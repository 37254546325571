@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-light-300.eot);
  src: url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-light-300.eot) format('embedded-opentype'),
    url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-light-300.woff2) format('woff2'),
    url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-light-300.woff) format('woff'),
    url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-light-300.ttf) format('truetype'),
    url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-light-300.svg) format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-regular-400.eot);
  src: url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-regular-400.eot) format('embedded-opentype'),
    url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-regular-400.woff2) format('woff2'),
    url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-regular-400.woff) format('woff'),
    url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-regular-400.ttf) format('truetype'),
    url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-regular-400.svg) format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-solid-900.eot);
  src: url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-solid-900.eot) format('embedded-opentype'),
    url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-solid-900.woff2) format('woff2'),
    url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-solid-900.woff) format('woff'),
    url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-solid-900.ttf) format('truetype'),
    url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-solid-900.svg) format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Brands';
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-brands-400.eot);
  src: url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-brands-400.eot) format('embedded-opentype'),
    url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-brands-400.woff2) format('woff2'),
    url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-brands-400.woff) format('woff'),
    url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-brands-400.ttf) format('truetype'),
    url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-brands-400.svg) format('svg');
}

@font-face {
  font-family: 'Font Awesome 5 Duotone';
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-duotone-900.eot);
  src: url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-duotone-900.eot) format('embedded-opentype'),
    url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-duotone-900.woff2) format('woff2'),
    url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-duotone-900.woff) format('woff'),
    url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-duotone-900.ttf) format('truetype'),
    url(https://cdn.queseguro.co/shared/fontawesome/webfonts/fa-duotone-900.svg) format('svg');
}
